<template>
  <div id="campaignDetailPage">
    <!-- Page Loader -->
    <div class="loadingDiv" v-if="fetchingCampaignDetails" v-loading="true" style="min-height: 300px; width: 100%"></div>

    <!-- Page Header -->
    <el-card class="pageHeader" v-if="!fetchingCampaignDetails && campaignReport" shadow="never">
      <div slot="header" class="clearfix">
        <a @click="$router.push({ path: '/campaigns/' + campaignReport.campaign.channel })" style="cursor: pointer">
          <i class="el-icon-back"></i>
        </a>
        &nbsp;&nbsp;&nbsp;
        <span v-if="campaignReport.campaign.name">{{ campaignReport.campaign.name }}</span>
        <span v-else>--</span>

        <!-- Action Button -->
        <div class="actionBtn">
          <span class="actionBtnDivision">
            <el-tooltip class="item" effect="dark" content="Status" placement="bottom" v-if="campaignReport.campaign.status != 'draft'">
              <el-button size="mini" type="primary" plain class="editBtn" @click="showCampaignCustomerList()">Failed Messages</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Edit" placement="bottom" v-if="campaignReport.campaign.type != 'journey'">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle plain class="editBtn" @click="editCampaign()" v-if="campaignReport.campaign.status != 'ended'"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Refresh" placement="bottom">
              <el-button size="mini" type="primary" icon="el-icon-refresh" circle plain class="refreshBtn" @click="refreshPage()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Duplicate" placement="bottom" v-if="campaignReport.campaign.type != 'journey'">
              <el-button size="mini" type="primary" icon="el-icon-document-copy" circle plain class="makeCopyBtn" @click="cloneCampaign()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Delete" placement="bottom" v-if="campaignReport.campaign.type != 'journey'">
              <el-button size="mini" type="danger" icon="el-icon-delete" circle plain class="deleteBtn" @click="deleteCampaign()"></el-button>
            </el-tooltip>
          </span>
        </div>
      </div>
      <el-row class="infoSection">
        <el-col :span="8" class="formItemForDisplay">
          <span class="label"> TYPE: </span>
          <template v-if="campaignReport.campaign.type == 'journey'">
            <a target="_blank" :href="'/journey/' + campaignReport.campaign.journey_id" class="text-primary-600 hover:text-primary-500">{{ campaignTypeDisplayNames[campaignReport.campaign.type] }} </a>
          </template>
          <template v-else>
            {{ campaignTypeDisplayNames[campaignReport.campaign.type] }}
          </template>
        </el-col>
        <el-col :span="8" class="formItemForDisplay">
          <span class="label">STATUS: </span>
          <div style="display: inline-block">
            <div class="bg-green-700 text-white px-2 py-0.5 text-xs rounded" v-if="campaignReport.campaign.status == 'running'">RUNNING</div>
            <div class="bg-secondary-600 text-white px-2 py-0.5 text-xs rounded" v-else-if="campaignReport.campaign.status == 'scheduled'">SCHEDULED</div>
            <div class="bg-red-600 text-white px-2 py-0.5 text-xs rounded" v-else>{{ campaignReport.campaign.status.toUpperCase() }}</div>
          </div>
        </el-col>
        <el-col :span="8" class="formItemForDisplay">
          <span class="label"> CHANNEL: </span>
          {{ campaignChannelDisplayNames[campaignReport.campaign.channel] }}
        </el-col>
      </el-row>

      <!-- Tabs -->
      <div class="tabHeaders">
        <div class="tabItem" :class="{ activeTab: selectedTab == 'overview' }" @click="onTabClicked('overview')">Overview</div>
        <!-- <div class="tabItem" :class="{ activeTab: selectedTab == 'goals' }" @click="onTabClicked('goals')">Conversion Goals</div> -->
        <div class="tabItem" :class="{ activeTab: selectedTab == 'content' }" @click="onTabClicked('content')">Campaign Content</div>
        <div class="tabItem" :class="{ activeTab: selectedTab == 'recipients' }" @click="onTabClicked('recipients')">Recipient Activity</div>
      </div>
    </el-card>

    <!-- Overview Tab -->
    <div class="overviewTab" v-if="!fetchingCampaignDetails && campaignReport && selectedTab == 'overview'">
      <!-- Time picker -->
      <div class="timingInfoContainer">
        <!-- <el-button type="info" plain @click="showRevenueTrackingEventPopup" class="trackingSetupLink" size="small">Revenue Tracking Setup</el-button> -->
        <div class="datePicker timingItem" v-if="this.campaignReport.campaign.type != 'oneTime'">
          <!-- <div class="label">Show Data For</div> -->
          <span class="datepickerInFilter">
            <el-select size="small" v-model="analyticsTimingFilter" v-show="analyticsTimingFilter != ''">
              <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
            </el-select>
            <el-date-picker v-if="analyticsTimingFilter == ''" v-model="analytics.dateFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="onOverviewTimingChange"> </el-date-picker>
          </span>
        </div>
      </div>

      <!-- Header - Statistics -->
      <div class="box-card statsContainer" shadow="never" v-loading="fetchingCampaignStats">
        <template v-if="campaignStats">
          <div class="borderRight campaignInfoColumn">
            <div class="oneColumnDashboardComponent" v-if="campaignReport.campaign.type != 'journey' && campaignReport.campaign.status != 'ended'">
              <div class="title color1">ELIGIBLE</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.eligible ? campaignStats.eligible : 0) }}</div>
            </div>
            <div class="oneColumnDashboardComponent">
              <div class="title color1">SENT</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.sent ? campaignStats.sent : 0) }}</div>
            </div>
            <div class="oneColumnDashboardComponent" v-if="campaignReport.campaign.type == 'journey' || campaignReport.campaign.status == 'ended'">
              <div class="title color1">SKIPPED</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.skipped ? campaignStats.skipped : 0) }}</div>
            </div>
          </div>
          <div class="borderRight campaignInfoColumn">
            <div class="oneColumnDashboardComponent">
              <div class="title color1">DELIVERED</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.delivered ? campaignStats.delivered : 0) }}</div>
              <div class="percentage">{{ campaignStats.delivered_perc ? campaignStats.delivered_perc : 0 }}%</div>
            </div>
            <div class="oneColumnDashboardComponent">
              <div class="title color1">FAILED</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.failed ? campaignStats.failed : 0) }}</div>
              <div class="percentage">{{ campaignStats.failed_perc ? campaignStats.failed_perc : 0 }}%</div>
            </div>
          </div>
          <div class="borderRight campaignInfoColumn">
            <div class="oneColumnDashboardComponent" v-if="['email', 'whatsApp'].indexOf(campaignReport.campaign.channel) >= 0">
              <div class="title color2">UNIQUE OPENS</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.unique_opened ? campaignStats.unique_opened : 0) }}</div>
              <div class="percentage">{{ campaignStats.unique_opened_perc ? campaignStats.unique_opened_perc : 0 }}%</div>
            </div>
            <div class="oneColumnDashboardComponent">
              <div class="title color2">UNIQUE CLICKS</div>
              <div class="numbers">{{ getNumberWithComma(campaignStats.unique_clicked ? campaignStats.unique_clicked : 0) }}</div>
              <div class="percentage">{{ campaignStats.unique_clicked_perc ? campaignStats.unique_clicked_perc : 0 }}%</div>
            </div>
          </div>
          <div class="campaignInfoColumn">
            <template v-if="campaignStats.revenue != null">
              <div class="oneColumnDashboardComponent">
                <div class="title color3">UNIQUE CONVERSIONS</div>
                <div class="numbers">{{ getNumberWithComma(campaignStats.conversions ? campaignStats.conversions : 0) }}</div>
                <div class="percentage">{{ campaignStats.conversions_perc ? campaignStats.conversions_perc : 0 }}%</div>
              </div>
              <div class="oneColumnDashboardComponent">
                <div class="title color3">TOTAL REVENUE</div>
                <div class="numbers">{{ getNumberWithCommaAndDecimal(campaignStats.revenue ? campaignStats.revenue : 0) }}</div>
                <el-button type="text" size="mini" class="detailsButton" @click="showDefaultRevenueDetails">View All Conversions</el-button>
              </div>
            </template>
            <template v-else>
              <el-link _target="blank" class="trackingSetupLink" underline="false" href="/settings/events" type="primary" size="mini" plain>Setup Revenue Tracking</el-link>
            </template>
          </div>
        </template>
      </div>

      <!-- Campaign Info Tab -->
      <template v-if="campaignReport.campaign.type != 'journey'">
        <!-- Audience Card -->
        <div class="overviewCard border bg-white rounded-md" shadow="never">
          <!-- Card Header -->
          <div class="bg-gray-100 border-b p-2.5">TARGET AUDIENCE</div>
          <!-- Card Content -->
          <div>
            <div class="p-3">
              <div class="mb-1">INCLUDE AUDIENCE</div>
              <!-- Custom Audience -->
              <template v-if="campaignReport.audiance.include.audiance_type == 'custom'">
                <UserPropertyFilterComponent ref="userFilterSummary" v-bind:readOnly="true" v-bind:formattedPropertyFilter="campaignReport.audiance.include.filter"></UserPropertyFilterComponent>
              </template>
              <!-- Segment Audience -->
              <div class="formItemForDisplay" v-else-if="campaignReport.audiance.include.audiance_type == 'segment'">
                <span class="label"> Customers In Segment: </span>
                {{ campaignReport.audiance.include.segment.name + (campaignReport.audiance.include.segment.is_deleted ? ' (Deleted)' : '') }}
              </div>
              <div class="formItemForDisplay" v-else-if="campaignReport.audiance.include.audiance_type == 'segment-v2'">
                <template v-if="campaignReport.audiance.include.segment_v2.segments && campaignReport.audiance.include.segment_v2.segments.length > 0">
                  <div class="label">Customers In Segment:</div>
                  <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in campaignReport.audiance.include.segment_v2.segments">
                    {{ segment.name }}
                  </el-tag>
                </template>
                <template v-if="campaignReport.audiance.include.segment_v2.lists && campaignReport.audiance.include.segment_v2.lists.length > 0">
                  <div class="label mt-3">Customers In Static List:</div>
                  <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in campaignReport.audiance.include.segment_v2.lists">
                    {{ segment.name }}
                  </el-tag>
                </template>
              </div>
              <!-- Static List Audience -->
              <div class="formItemForDisplay" v-else-if="campaignReport.audiance.include.audiance_type == 'static_list'">
                <span class="label"> Customers In Static List: </span>
                {{ campaignReport.audiance.include.static_list.name + (campaignReport.audiance.include.static_list.is_deleted ? ' (Deleted)' : '') }}
              </div>
              <!-- All Customers Audience -->
              <template v-else> All Customers </template>
              <div class="unsubscribedMsg" v-if="campaignReport.campaign && campaignReport.campaign.ignore_unsubscribed_user > 0">The campaign is executing for unsubscribed email users as well.</div>
            </div>

            <div class="p-3 border-t" v-if="campaignReport.audiance.exclude">
              <div class="mb-1">EXCLUDE AUDIENCE</div>
              <!-- Custom Audience -->
              <template v-if="campaignReport.audiance.exclude.audiance_type == 'custom'">
                <UserPropertyFilterComponent ref="userFilterSummary" v-bind:readOnly="true" v-bind:formattedPropertyFilter="campaignReport.audiance.exclude.filter"></UserPropertyFilterComponent>
              </template>
              <!-- Segment Audience -->
              <div class="formItemForDisplay" v-else-if="campaignReport.audiance.exclude.audiance_type == 'segment'">
                <span class="label"> Customers In Segment: </span>
                {{ campaignReport.audiance.exclude.segment.name + (campaignReport.audiance.exclude.segment.is_deleted ? ' (Deleted)' : '') }}
              </div>
              <div class="formItemForDisplay" v-else-if="campaignReport.audiance.exclude.audiance_type == 'segment-v2'">
                <template v-if="campaignReport.audiance.exclude.segment_v2.segments">
                  <div class="label">Customers In Segment:</div>
                  <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in campaignReport.audiance.exclude.segment_v2.segments">
                    {{ segment.name }}
                  </el-tag>
                </template>
                <template v-if="campaignReport.audiance.exclude.segment_v2.lists && campaignReport.audiance.exclude.segment_v2.lists.length > 0">
                  <div class="label mt-3">Customers In Static List:</div>
                  <el-tag class="mr-2.5" type="info" size="small" effect="dark" :key="index" v-for="(segment, index) in campaignReport.audiance.exclude.segment_v2.lists">
                    {{ segment.name }}
                  </el-tag>
                </template>
              </div>
              <!-- Static List Audience -->
              <div class="formItemForDisplay" v-else-if="campaignReport.audiance.exclude.audiance_type == 'static_list'">
                <span class="label"> Customers In Static List: </span>
                {{ campaignReport.audiance.exclude.static_list.name + (campaignReport.audiance.exclude.static_list.is_deleted ? ' (Deleted)' : '') }}
              </div>
              <!-- All Customers Audience -->
              <template v-else> All Customers </template>
            </div>
          </div>
        </div>

        <!-- WHEN Card -->
        <el-card class="overviewCard" shadow="never">
          <div class="headerDiv" slot="header">CAMPAIGN TIMING</div>
          <!-- Start Time - End Time -->
          <el-row>
            <el-col :span="8" class="formItemForDisplay">
              <span class="label"> Type: </span>
              {{ campaignTypeDisplayNames[campaignReport.campaign.type] }}
            </el-col>
            <el-col :span="8" class="formItemForDisplay">
              <span class="label"> Start Time: </span>
              {{ campaignReport.campaign.start_time }}
            </el-col>
            <!-- <el-col :span="8" class="formItemForDisplay">
              <span class="label"> End Time: </span>
              <span v-if="campaignReport.campaign.end_type == 'selectedDate'"> {{ campaignReport.campaign.end_time }}</span>
              <span v-else>Never</span>
            </el-col> -->
          </el-row>
          <el-divider v-if="campaignReport.campaign.type != 'oneTime'"></el-divider>
        </el-card>

        <!-- Goals -->
        <el-card shadow="never" class="mb-4">
          <div class="cardHeader" slot="header">
            <span>Conversions Tracking</span>
          </div>
          <div class="utmDescription">
            <el-row :gutter="30" v-if="campaignReport.goal">
              <el-col :span="8">
                <div class="text-xs text-gray-400">Conversion Event</div>
                <div class="value">{{ campaignReport.goal.event }}</div>
              </el-col>
              <el-col class="descriptionItem" :span="8">
                <div class="text-xs text-gray-400">Revenue Attribute</div>
                <div class="value">{{ campaignReport.goal.property }}</div>
              </el-col>
              <el-col class="descriptionItem" :span="8">
                <div class="text-xs text-gray-400">Conversion Deadline</div>
                <div class="value">{{ campaignReport.goal.duration + ' ' + campaignReport.goal.duration_unit }}</div>
              </el-col>
            </el-row>
            <div v-else class="text-sm text-gray-400 text-center py-2">Conversion tracking not configured for this campaign.</div>
          </div>
        </el-card>

        <!-- Utm Info Card -->
        <el-card shadow="never" class="mb-4">
          <div class="cardHeader" slot="header">
            <span>Campaign UTM Parameters</span>
          </div>
          <div class="utmDescription">
            <el-row :gutter="30" v-if="campaignReport.utm_tracking_config">
              <el-col :span="8">
                <div class="text-xs text-gray-400">UTM Source</div>
                <div class="value">{{ campaignReport.utm_tracking_config.source }}</div>
              </el-col>
              <el-col class="descriptionItem" :span="8">
                <div class="text-xs text-gray-400">UTM Medium</div>
                <div class="value">{{ campaignReport.utm_tracking_config.medium }}</div>
              </el-col>
              <el-col class="descriptionItem" :span="8">
                <div class="text-xs text-gray-400">UTM Campaign</div>
                <div class="value">{{ campaignReport.utm_tracking_config.campaign }}</div>
              </el-col>
            </el-row>
            <div v-else class="text-sm text-gray-400 text-center py-2">UTM parameters not configured for this campaign.</div>
          </div>
        </el-card>
      </template>
    </div>

    <!-- Contents Tab -->
    <div class="camapignContentTab" v-if="!fetchingCampaignDetails && campaignReport && campaignReport.content && campaignReport && selectedTab == 'content'">
      <!-- Mobile Push Content -->
      <div class="contentPriview" v-if="campaignReport.campaign.channel == 'email'">
        <EmailBuilder v-bind:readOnly="true" ref="emailEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></EmailBuilder>
      </div>

      <!-- Mobile Push Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'mobilePush'">
        <MobilePushBuilder v-bind:readOnly="true" ref="mobilePushEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></MobilePushBuilder>
      </div>

      <!-- WebPush Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'webPush'">
        <WebPushBuilder v-bind:readOnly="true" ref="webPushEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></WebPushBuilder>
      </div>

      <!-- Sms Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'sms'">
        <SmsBuilder :readOnly="true" ref="smsEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></SmsBuilder>
      </div>

      <!-- WhatsApp Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'whatsApp'">
        <WhatsAppBuilder :readOnly="true" ref="whatsAppEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></WhatsAppBuilder>
      </div>

      <!-- In-App Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'inApp'">
        <InAppBuilder :readOnly="true" ref="inAppEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></InAppBuilder>
      </div>

      <!-- Custom Channel Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'custom'">
        <CustomChannelBuilder :readOnly="true" ref="customChannelEditor" :isJourneyCampaign="campaignReport.campaign.type == 'journey'"></CustomChannelBuilder>
      </div>

      <!-- Social Web Proof Content -->
      <div class="contentPriview" v-else-if="campaignReport.campaign.channel == 'webSocialProof'">
        <WebSocialProofBuilder :campaignTriggerEvent="actionEventFilter.eventName" :readOnly="true" ref="webSocialProofEditor"></WebSocialProofBuilder>
      </div>

      <div v-else class="noCampaignContent">Capaign Content Not Provided Yet.</div>
    </div>

    <!-- Recipient Activities Tab -->
    <div v-if="!fetchingCampaignDetails && campaignReport && selectedTab == 'recipients'">
      <CampaignRecipientReportComponent :campaignId="campaignReport.campaign.id"></CampaignRecipientReportComponent>
    </div>

    <!-- Email Attachment DailogBox -->
    <el-dialog class="emailAttachmentsDailogBox" title="Email Attachments" :visible.sync="showEmailAttachments" width="600px" center>
      <div v-if="campaignReport && campaignReport.content && campaignReport.content.email_attachments && campaignReport.content.email_attachments.length > 0">
        <table class="attachmentDetail">
          <tr v-for="item in campaignReport.content.email_attachments" :key="item.value" :value="item.value">
            <td class="fileName">
              {{ item.name }}
            </td>
            <td class="fileSize">
              {{ getBytesDisplayText(item.size, true) }}
            </td>
          </tr>
        </table>
      </div>
      <div class="noAttachmentDiv" v-else>No files attached</div>
    </el-dialog>

    <!-- Revenue Event List DialogBox -->
    <el-dialog class="revenueEventListDialogBox" :title="revenueDialogTitle" :visible.sync="showRevenueDetailsDialog" width="1000px" center>
      <div v-if="fetchingRevenueDetails" class="loader" v-loading="true"></div>
      <template v-else-if="revenueEventList.length > 0">
        <div class="revenueItem" v-for="(event, index) in revenueEventList" :key="index">
          <div class="userIcon">
            <a :href="`/customer/detail?id=${event[0].customer_id}`" target="_blank"><i class="el-icon-user-solid"></i></a>
          </div>
          <div class="treeContainer"><el-tree class="filter-tree" :data="event" :props="revenueTreeProps"> </el-tree></div>
        </div>
      </template>
      <div class="noDataDiv" v-else>No Revenue Data Found</div>
    </el-dialog>

    <!-- Campaign status dailog -->
    <CampaignCustomerList ref="campaignReportCustomerlist"></CampaignCustomerList>

    <RevenueTrackingEventPopup ref="revenueTrackingEventPopup" @changedRevenueTracking="fetchCampaignStats"></RevenueTrackingEventPopup>
  </div>
</template>
<style lang="scss" src="./campaignReport.scss"></style>

<script>
import campaignReportComponent from './campaignReportComponent';
export default campaignReportComponent;
</script>
